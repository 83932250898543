import 'whatwg-fetch'
import { get } from 'svelte/store'
import { getBaseUrl } from './mappings'
import { customerTypeStore } from '../stores'

export const fetchStoreData = ({ method }) => {
  const customerType = get(customerTypeStore).b2c ? 'B2C' : 'B2B'
  return window
    .fetch(`${getBaseUrl()}?${method}&customerType=${customerType}`)
    .then((res) => res.json())
}
