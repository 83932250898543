import { Store } from '../models'
import { substitutePhysicalForCommerceStores } from '../helpers/substitute-stores'

const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'Å',
  'Ä',
  'Ö',
]
const areas = alphabet.map((letter) => ({ letter }))

const getStoresWithPickup = (stores) => {
  return substitutePhysicalForCommerceStores(
    stores
      .map((item) => Store(item))
      .filter((store) => store.supportedDeliveryMethods.pickup)
  )
}

const mapStoresForPickup = (area, stores) => {
  const storesInCity = stores[area]
  if (area === 'Storstockholm') {
    const suburbs = []
    Object.keys(stores).forEach((suburb) => {
      suburbs.push({
        name: suburb,
        zip: stores[suburb][0].zipCode,
        pickupStores: getStoresWithPickup(stores[suburb]),
        homeDeliveryStores: stores[suburb].filter(
          (store) =>
            store.deliveryMethods &&
            store.deliveryMethods.includes('HOME_DELIVERY')
        ),
      })
    })
    return suburbs
  }
  return getStoresWithPickup(storesInCity)
}

export const formatCities = (citiesObj) => {
  Object.keys(citiesObj).forEach((cityLetter) => {
    areas.forEach((area) => {
      if (cityLetter === area.letter) {
        const areaObj = citiesObj[cityLetter]
        const cities = Object.keys(areaObj).map((city) => {
          const showSuburbs = city === 'Storstockholm'
          return {
            name: city,
            zip: !showSuburbs && areaObj[city][city][0].zipCode,
            showSuburbs,
            pickupStores: mapStoresForPickup(city, areaObj[city]),
            homeDeliveryStores: !showSuburbs
              ? areaObj[city][city].filter(
                  (store) =>
                    store.deliveryMethods &&
                    store.deliveryMethods.includes('HOME_DELIVERY')
                )
              : [],
          }
        })
        area.cities = cities
      }
    })
  })
  return areas
}

export const formatCityAndZipStores = (cityStores, zipStores) => {
  if (cityStores.length && zipStores.length) {
    cityStores[cityStores.length - 1].postDivide = true
  } else if (zipStores.length) {
    zipStores[0].preDivide = true
  }
  const cityStoreIds = cityStores.map((store) => store.id)
  const filteredZipStores = zipStores.filter(
    (store) => cityStoreIds.indexOf(store.id) === -1
  )
  return [...cityStores, ...filteredZipStores]
}
