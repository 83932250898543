export const Store = ({
  id,
  accountId,
  name,
  street,
  zipCode,
  city,
  area,
  slug,
  storeFormat,
  marketingText,
  deliveryMethods,
  customerTypes,
  customLogoURI,
}) => ({
  id,
  accountId,
  name,
  address: {
    street,
    zipCode,
    city,
  },
  area,
  slug,
  profile: storeFormat,
  description: marketingText,
  customLogoURI,
  supportedDeliveryMethods: {
    pickup: (deliveryMethods && deliveryMethods.includes('PICKUP')) || false,
    homeDelivery:
      (deliveryMethods && deliveryMethods.includes('HOME_DELIVERY')) || false,
  },
  supportedCustomerTypes: {
    b2c: customerTypes && customerTypes.includes('B2C'),
    b2b: customerTypes && customerTypes.includes('B2B'),
  },
})
