<script>
  import { backdropShownStore as backDropVisible } from '../stores'
</script>

<style>
  .store-selector-app__backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
</style>

{#if $backDropVisible}
  <div class="store-selector-app__backdrop" />
{/if}
