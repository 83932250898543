import { trackClick, trackSelectStore, trackInitial } from './track'

const geoOptions = {
  enableHighAccuracy: false,
  timeout: 5 * 1000,
  maximumAge: 1000 * 60 * 10,
}

export const getCurrentPosition = () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      resolve,
      (positionError) =>
        reject(new Error(`${positionError.code}, ${positionError.message}`)),
      geoOptions
    )
  })

export { trackSelectStore, trackClick, trackInitial }
