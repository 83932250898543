<script context="module">
  export const hasCustomLogo = (id) => {
    const customLogos = [
      '01253',
      '01292',
      '01437',
      '00977',
      '01447',
      '09259',
      '01256',
      '01942',
      '01201',
      '01160',
      '15076',
      '00966',
      '01952',
      '14926'
    ]
    return customLogos.indexOf(id) !== -1
  }
</script>

<script>
  export let id
  export let name

  const map = {
    '01253': 'baner.png',
    '01292': 'bea.png',
    '01437': 'brottby.png',
    '00977': 'ettan.png',
    '01447': 'flygfyren.png',
    '09259': 'hajen.png',
    '01256': 'karlaplan.png',
    '01942': 'city.png',
    '01952': 'city.png',
    '01201': 'stop.svg',
    '01160': 'tappstrom.png',
    '15076': 'taby-metro.png',
    '00966': 'matkassen.svg',
    '14926': 'faltoversten.png'
  }
</script>

<img
  alt={name}
  src={`https://assets.icanet.se/image/upload/q_auto,f_auto,h_200/ecom-assets/custom-store-logos/${map[id]}`}
/>
