<script>
  import { onMount } from 'svelte'
  import CloseButton from './CloseButton.svelte'
  import MenuBar from './MenuBar.svelte'
  import Header from './Header.svelte'
  import ZipcodeField from './ZipcodeField.svelte'
  import Menu from './Menu.svelte'
  import StoresList from './StoresList.svelte'
  import CityListView from './CityList.svelte'
  import SearchResultText from './SearchResultText.svelte'
  import OfflineStores from './OfflineStores.svelte'
  import AlternativeListing from './AlternativeListing.svelte'

  import {
    backdropShownStore as backDrop,
    cityListViewStore as cityListShown,
    selectedCityStore as selectedCity,
    selectedZipCodeStore as selectedZipCode,
    listModeStore as listMode,
    offlineStoreModeStore as offlineStoreMode
  } from '../stores'

  onMount(() => {
    backDrop.set(true)
  })
</script>

<style lang="scss">
  .store-selector {
    max-width: 1000px;
    max-width: 100%;
    background-color: var(--color-ica-red);
    position: absolute;
    top: var(--spacing);
    left: calc(var(--spacing) / 2);
    right: calc(var(--spacing) / 2);
    margin-bottom: var(--spacing);
    border-radius: 8px;
    z-index: 11;

    @media (--for-tablet-landscape-up) {
      top: calc(var(--spacing) * 2);
      left: 50%;
      transform: translateX(-50%);
      width: 1000px;
    }
  }
  :global(.ss-list) {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  :global(.ss-button) {
    border: 0;
    outline: 0;
    font-family: ICATextNy, arial, serif;
    cursor: pointer;
    background-color: transparent;
  }
  :global(.ss-paragraph) {
    margin: 0;
    font-size: 14px;
    color: var(--color-soft-black);
    font-family: ICATextNy, arial, sans-serif;
  }
</style>

<div class="store-selector">
  <CloseButton />
  <MenuBar />
  {#if $listMode && ($selectedCity || $selectedZipCode)}
    <SearchResultText />
    <Menu />
    {#if $offlineStoreMode}
      <AlternativeListing>
        <OfflineStores />
      </AlternativeListing>
    {:else}
      <StoresList />
    {/if}
  {:else if $cityListShown}
    <CityListView />
  {:else}
    <Header />
    <ZipcodeField />
    <StoresList />
    <Menu />
  {/if}
</div>
