<script>
  import Loading from './Loading.svelte'
  import {
    selectedZipCodeStore as selectedZipCode
  } from '../stores'

  export let handleZipCodeChange
</script>

<style lang="scss">
  input {
    line-height: 50px;
    width: calc(100% - 30px);
    background-color: transparent;
    border: 0;
    display: block;
    border-radius: 50px;
    outline: 0;
    padding: 0 var(--spacing);
    font-family: ICATextNy, arial, serif;
    letter-spacing: 2px;
    font-size: 24px;
    letter-spacing: 3px;
    -moz-appearance: textfield;
    color: var(--color-black);
  }
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  label {
    color: var(--color-black);
    font-family: ICATextNy, arial, serif;
    line-height: 50px;
    font-size: 16px;
    white-space: nowrap;

    @media (--for-tablet-portrait-up) {
      font-size: 18px;
    }
  }
  div {
    position: relative;
    display: flex;
    flex: 0 1 100%;
    margin: 0 var(--spacing);
    max-width: 550px;
    border-radius: 50px;
    background-color: var(--color-white);
    padding: 0 calc(var(--spacing) * 2);

    @media (--for-tablet-portrait-up) {
      margin: 0 auto;
    }
  }
</style>

<div>
  <label for="zipcode">Ditt postnummer:</label>
  <input
    on:input={handleZipCodeChange}
    name="zipcode"
    id="zipcode"
    type="number"
    pattern="[0-9]*"
    maxlength="5"
    value={$selectedZipCode} />
  <Loading position="right" />
</div>
