import { get } from 'svelte/store'
import { fetchStoreData } from './fetch'
import { formatCities, formatCityAndZipStores } from './fetch/format'
import {
  getCurrentPosition,
  trackInitial,
  trackSelectStore,
  trackClick,
} from './utils'
import {
  customerTypeStore,
  selectedZipCodeStore,
  citiesStore,
  backdropShownStore,
  storesForPickupStore,
  storesForHomeDeliveryStore,
  disableHomeDeliveryTabStore,
  positioningCoordsStore,
  loadingStore,
  listModeStore,
  cityListViewStore,
  noResultsStore,
  selectedCityStore,
  cityStoresCountStore,
  contextStore,
  storesOfflineStore,
  offlineStoreModeStore,
  categoryIdStore,
  ciStore,
  customUrlStore,
  filterStore,
} from './stores'
import { substitutePhysicalForCommerceStores } from './helpers/substitute-stores'
import { Store } from './models'
import { trackEvent } from './utils/track'

const showNoResults = () => noResultsStore.set(true)

const hideNoResults = () => noResultsStore.set(false)

const goToSelectedCity = ({ name, pickupStores, homeDeliveryStores }) => {
  hideCityList()
  setSelectedCity(name)
  if (!pickupStores.length && homeDeliveryStores.length) {
    disableHomeDeliveryTabStore.set(false)
  }
  setListMode('pickup')
  setCityStoreCount(pickupStores.length)
  storesForPickupStore.update((zipStores) =>
    formatCityAndZipStores(pickupStores, zipStores)
  )
}

export const getCitiesList = () => {
  showLoading()
  return fetchStoreData({ method: 'groupby=citygroup' })
    .then((cities) => {
      const citiesData = formatCities(cities)
      citiesStore.set(citiesData)
      return hideLoading()
    })
    .catch((err) => {
      console.error(err)
      hideLoading()
    })
}

export const getOfflineStores = () => {
  return fetchStoreData({
    method: `offlinestore=true&zip=${get(selectedZipCodeStore)}`,
  })
    .then(({ offline }) => {
      storesOfflineStore.set(offline.map((store) => Store(store)))
      return hideLoading()
    })
    .catch((err) => {
      console.error(err)
      hideLoading()
    })
}

export const doPositionSearch = () => {
  resetStoresState()
  hideCityList()
  showLoading()
  trackClick({ action: 'Hitta position' })
  getCurrentPosition()
    .then((position) => setCoordinates(position.coords))
    .catch((err) => {
      console.error(err)
      hideLoading()
    })
}

export const doZipCodeSearch = ({
  zip,
  suppressHomeDelivery = false,
  areaName = '',
}) => {
  hideNoResults()
  showLoading()
  return fetchStoreData({ method: `zip=${zip}` })
    .then(({ forHomeDelivery = [], forPickupDelivery = [] }) => {
      const forPickupDeliveryStores = substitutePhysicalForCommerceStores(
        forPickupDelivery
      )
      storesForPickupStore.set(
        forPickupDeliveryStores.map((store) => Store(store))
      )
      if (!suppressHomeDelivery) {
        storesForHomeDeliveryStore.set(
          forHomeDelivery.map((store) => Store(store))
        )
      }
      const noResults =
        !forHomeDelivery.length && !forPickupDeliveryStores.length
      if (noResults) {
        trackClick({ action: 'Inga butiker hittades', label: zip })
        showNoResults()
      }
      disableHomeDeliveryTabStore.set(!forHomeDelivery.length)
      if (suppressHomeDelivery) {
        trackEvent({
          event: 'genericStoreSelectCity',
          linkName: areaName,
        })
      } else {
        trackEvent({
          event: 'genericStoreSearchZipcode',
          zipCode: zip,
          Context: noResults ? 'misslyckat resultat' : 'lyckat resultat',
        })
      }
      return hideLoading()
    })
    .catch((err) => {
      console.error(err)
      hideLoading()
    })
}

export const searchWithCoordinates = (coords) => {
  hideNoResults()
  return fetchStoreData({
    method: `latitude=${coords.lat}&longitude=${coords.long}`,
  })
    .then(({ forHomeDelivery = [], forPickupDelivery = [], zipCode }) => {
      storesForPickupStore.set(forPickupDelivery.map((store) => Store(store)))
      storesForHomeDeliveryStore.set(
        forHomeDelivery.map((store) => Store(store))
      )
      setSelectedZipCode(zipCode)
      if (!forHomeDelivery.length && !forPickupDelivery.length) {
        showNoResults()
      } else {
        trackEvent({
          event: 'genericStoreSearchZipcode',
          zipCode,
        })
      }
      return hideLoading()
    })
    .catch((err) => {
      hideLoading()
      console.error(err)
    })
}

export const setCoordinates = (coords) =>
  positioningCoordsStore.set({ long: coords.longitude, lat: coords.latitude })

export const setCustomerType = (customerTypeObj) =>
  customerTypeStore.set(customerTypeObj)

export const setSelectedZipCode = (zip) => selectedZipCodeStore.set(zip)

export const resetZipCode = () => selectedZipCodeStore.set(null)

export const setSelectedCity = (name) => selectedCityStore.set(name)

export const resetSelectedCity = () => selectedCityStore.set(null)

export const showBackdrop = () => backdropShownStore.set(true)

export const showLoading = () => loadingStore.set(true)

export const hideLoading = () => loadingStore.set(false)

export const showCityList = () => cityListViewStore.set(true)

export const hideCityList = () => cityListViewStore.set(false)

export const resetListMode = () => listModeStore.set(null)

export const setListMode = (mode) => listModeStore.set(mode)

export const setOfflineStoreMode = (mode) => offlineStoreModeStore.set(mode)

export const setCityStoreCount = (count) => cityStoresCountStore.set(count)

export const resetCityStoreCount = () => cityStoresCountStore.set(0)

export const setContext = (context) => contextStore.set(context)

export const setCategoryId = (categoryId) => categoryIdStore.set(categoryId)

export const setCI = (ci) => ciStore.set(ci)

export const setCustomUrl = (customUrl) => customUrlStore.set(customUrl)

export const setFilter = (filter) => filterStore.set(filter)

export const setStoresOfCity = (area) => {
  if (area.zip) {
    return doZipCodeSearch({
      zip: area.zip,
      suppressHomeDelivery: true,
      areaName: area.name,
    }).then(() => goToSelectedCity(area))
  } else {
    return goToSelectedCity(area)
  }
}

export const selectStore = (store, index) => {
  const positionInList = index + 1
  const customerType = get(customerTypeStore)
  const ci = get(ciStore)
  const categoryId = get(categoryIdStore)
  const customUrl = get(customUrlStore)
  const filter = get(filterStore)
  const baseUrl = customerType.b2b
    ? 'https://handlaforetag.ica.se'
    : 'https://handlaprivatkund.ica.se'

  let destinationUrl = `${baseUrl}/stores/${store.accountId}`

  if (ci) {
    destinationUrl = `${baseUrl}/stores/${store.accountId}/products/${ci}/details`
  }
  if (categoryId) {
    destinationUrl = `${baseUrl}/stores/${
      store.accountId
    }/redirect/category?retailerId=${categoryId}${filter ? `${filter}` : ''}`
  }
  if (customUrl) {
    destinationUrl = `${baseUrl}/stores/${store.accountId}${customUrl}`
  }

  trackEvent({
    event: 'genericStoreSelectStore',
    storeInformation: {
      id: store.id,
      accountNumber: store.accountId,
      name: store.name,
      profile: store.profile,
      zipCode: store.address.zipCode,
      city: store.address.city,
    },
  })

  trackSelectStore({ url: destinationUrl, positionInList })

  window.location.assign(destinationUrl)
}

export const resetStoresState = () => {
  storesForPickupStore.set([])
  storesForHomeDeliveryStore.set([])
  resetListMode()
  resetSelectedCity()
  resetCityStoreCount()
}

export const resetStoreSelectorState = () => {
  resetStoresState()
  resetZipCode()
  hideCityList()
}

export const setLaunchState = ({
  context = 'generic-store',
  searchWith = { zipCode: null, position: false, cities: false },
  customerType = { b2c: true, b2b: false },
  ci = null,
  categoryId = null,
  customUrl = null,
  filterString = null,
} = {}) => {
  const { zipCode, position, cities } = searchWith
  setContext(context)
  setCustomerType(customerType)
  setCategoryId(categoryId)
  setCI(ci)
  setCustomUrl(customUrl)
  setFilter(filterString)

  if (zipCode) {
    setSelectedZipCode(zipCode)
    doZipCodeSearch({ zip: zipCode })
  }
  if (position) {
    doPositionSearch()
  }
  if (cities) {
    showCityList()
  }
  trackInitial()
}
