import { writable } from 'svelte/store'

export const customerTypeStore = writable({ b2c: false, b2b: false })

export const citiesStore = writable({})

export const selectedZipCodeStore = writable('')

export const selectedCityStore = writable(null)

export const cityStoresCountStore = writable(0)

export const backdropShownStore = writable(false)

export const cityListViewStore = writable(false)

export const storesForPickupStore = writable([])

export const storesForHomeDeliveryStore = writable([])

export const disableHomeDeliveryTabStore = writable(true)

export const storesOfflineStore = writable([])

export const listModeStore = writable(null)

export const positioningCoordsStore = writable({})

export const loadingStore = writable(false)

export const noResultsStore = writable(false)

export const contextStore = writable(null)

export const offlineStoreModeStore = writable(false)

export const categoryIdStore = writable(null)

export const ciStore = writable(null)

export const customUrlStore = writable(null)

export const filterStore = writable(null)
