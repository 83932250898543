import { setLaunchState } from './actions'
import { setupListeners } from './listeners'
import StoreSelectorComponent from './components/StoreSelectorComponent.svelte'
import Backdrop from './components/Backdrop.svelte'

export const instantiate = (config) => {
  const wrapper = document.createElement('div')
  wrapper.setAttribute('id', 'store-selector-app')
  document.body.appendChild(wrapper)

  /* eslint-disable no-new */
  new Backdrop({
    target: document.body,
  })

  setupListeners()
  setLaunchState(config)
  return new StoreSelectorComponent({
    target: wrapper,
  })
}
