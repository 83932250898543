const genericEvent = {
  event: 'genericEvent',
  eventCategory: 'Butiksväljare',
  eventValue: undefined,
  eventNonInteraction: false,
}

const pushEvent = (eventObj) =>
  window.dataLayer && window.dataLayer.push(eventObj)

export const trackEvent = (event) => pushEvent(event)

export const trackClick = ({ action, label = 'klick' }) =>
  pushEvent({
    ...genericEvent,
    eventAction: action,
    eventLabel: label,
  })

export const trackSelectStore = ({ url, positionInList }) =>
  pushEvent({
    ...genericEvent,
    eventAction: 'Välj butik',
    label: url,
    storeListPosition: positionInList,
  })

export const trackInitial = () =>
  pushEvent({ event: 'genericStoreViewStoreSelector' })
