import { positioningCoordsStore } from '../stores'
import { searchWithCoordinates } from '../actions'

export const setupListeners = () => {
  positioningCoordsStore.subscribe((coords) => {
    if (Object.entries(coords).length !== 0) {
      searchWithCoordinates(coords)
    }
  })
}
