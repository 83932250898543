<script>
  import {
    customerTypeStore as customerType,
    listModeStore as listMode,
    selectedZipCodeStore as selectedZipCode,
    selectedCityStore as selectedCity,
    cityListViewStore as cityListShown
  } from '../stores'
  import { resetStoreSelectorState, setCustomerType } from '../actions'
  import { trackClick } from '../utils'

  const chooseCustomerType = ({ b2c = false, b2b = false }) => {
    trackClick({ action: 'Välj kundtyp', label: b2c ? 'B2C' : 'B2B' })
    resetStoreSelectorState()
    setCustomerType({ b2c, b2b })
  }
</script>

<style lang="scss">
  nav {
    border-radius: 8px 8px 0 0;
    background-color: var(--color-white);

    &.inverted {
      background-color: var(--color-ica-red);

      & button {
        background-color: #d11a04;
        color: var(--color-white);
      }
      & :global(.selected) {
        background-color: var(--color-white);
        color: var(--color-ica-red);
        &:after {
          border-color: var(--color-white);
        }
      }
    }

    & li:first-child button {
      border-radius: 8px 0 0 0;
    }
  }
  ul {
    display: flex;
  }
  button {
    padding: var(--spacing) calc(var(--spacing) * 2);
    background-color: var(--color-store-header);
    font-size: 14px;

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-store-header);
      }
    }

    &.selected {
      background-color: var(--color-ica-red);
      color: var(--color-white);
      position: relative;
      cursor: default;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: rotate(-45deg);
        border-width: 4px 4px 4px;
        border-style: solid;
        border-color: var(--color-ica-red);
        margin: -4px 0 0 -4px;
      }
    }
  }
</style>

<nav
  class:inverted={($listMode && ($selectedZipCode || $selectedCity)) || $cityListShown}>
  <ul class="ss-list">
    <li>
      <button
        class="ss-button"
        data-automation-id="store-selector-choose-b2c"
        disabled={$customerType.B2C}
        on:click={() => chooseCustomerType({ b2c: true })}
        class:selected={$customerType.b2c}>
        Privat
      </button>
    </li>
    <li>
      <button
        class="ss-button"
        data-automation-id="store-selector-choose-b2b"
        disabled={$customerType.B2B}
        on:click={() => chooseCustomerType({ b2b: true })}
        class:selected={$customerType.b2b}>
        Företag
      </button>
    </li>
  </ul>
</nav>
