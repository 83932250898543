<script>
  import {
    selectedZipCodeStore as selectedZipCode,
    selectedCityStore as selectedCity
  } from '../stores'
</script>

<style>
  h2 {
    font-family: ICARubrik, arial, serif;
    font-weight: 300;
    font-size: 30px;
    color: var(--color-white);
    text-align: center;
    padding: calc(var(--spacing) * 2) calc(var(--spacing) * 2) 0
      calc(var(--spacing) * 2);
    margin: 0;
  }
  strong {
    font-weight: 600;
  }
</style>

<header>
  <h2>
    {#if $selectedZipCode}
      Butiker med e-handel för postnummer
      <strong>{$selectedZipCode}</strong>
    {:else if $selectedCity}
      Butiker med e-handel i
      <strong>{$selectedCity}</strong>
    {:else}
      Alla orter med ICA-butiker som
      <strong>erbjuder e-handel</strong>
    {/if}
  </h2>
</header>
