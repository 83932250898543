export const getBaseUrl = () => {
  const host = window.location.hostname
  const getBaseUrl = () => {
    switch (host) {
      case 'ica.se':
        return 'handla.ica.se'
      case 'www.ica.se':
        return 'handla.ica.se'
      case 'localhost':
        return 'qa.handla.ica.se'
      default:
        return host
    }
  }
  return `https://${getBaseUrl()}/api/store/v1`
}
