import './polyfills'
import { instantiate } from './App'

export let onDestroy
export const launch = (config) => {
  onDestroy = config.onDestroy
  instantiate(config)
}

export const destroy = () => {
  document.getElementById('store-selector-app').remove()
  document.querySelector('.store-selector-app__backdrop').remove()

  if (onDestroy) {
    onDestroy()
  }
}
