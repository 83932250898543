<script>
  import { onMount } from 'svelte'
  import { storesOfflineStore as offlineStores } from '../stores'
  import { getOfflineStores } from '../actions'

  onMount(() => {
    getOfflineStores()
  })
</script>

<style lang="scss">
  h4 {
    font-size: 26px;
    font-family: ICAHand, arial, sans-serif;
    margin: 0 auto var(--spacing) auto;
  }
  p,
  li {
    text-align: center;
    font-family: ICATextNy, arial, sans-serif;
    margin: 0 0 var(--spacing) 0;
  }
  li {
    font-weight: 500;
  }
  ul {
    list-style: none;
    margin: var(--spacing) 0 0 0;
  }
</style>

<h4>Varför hittar jag inte min butik?</h4>
<p>
  Följande butiker i ditt område erbjuder
  <strong>inte</strong>
  e-handel:
</p>
<ul>
  {#if $offlineStores.length}
    {#each $offlineStores as offlineStore}
      <li>{offlineStore.name}</li>
    {/each}
  {/if}
</ul>
